import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { generateYears } from "../../../utils/Helper";
import {
  valideWhiteSpace,
  valideNumber,
  validateDecimal,
  unformatNumber,
  captilizeString,
} from "../../../utils/Helper";
import {
  addFile,
  addBase64FormatFile,
  reInitialize,
} from "../../../redux/components/add-comp/Action";
import CompService from "../../../services/component/Comp";
import ProfileService from "../../../services/component/Profile";
import addIcon from "../../../assets/icons/circular-orange-icon.svg";
import ImageUpload from "../../../components/dialog/ImageUpload";
import { COMP } from "../../../utils/Constant";
import AlertDialog from "../../../components/dialog/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Details({ view, handleBack, confidential }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const value = useSelector((state) => state?.addComp?.detail);
  const unit = useSelector((state) => state?.auth?.user?.rentUnit?.toString());
  const fileObj = useSelector((state) => state?.addComp?.file);
  const base64Obj = useSelector((state) => state?.addComp?.base64);
  const addressTab = useSelector((state) => state?.addComp?.address);
  const basicInfoTab = useSelector((state) => state?.addComp?.basicInfo);
  const seller = useSelector((state) => state?.addComp?.sellerBroker);
  const buyer = useSelector((state) => state?.addComp?.buyerBroker);
  const companyValue = useSelector((state) => state?.addComp?.companyName);
  const addressTabSubmit = useSelector(
    (state) => state?.addComp?.addressTabSubmit
  );
  const basicInfoTabSubmit = useSelector(
    (state) => state?.addComp?.basicInfoTabSubmit
  );
  const brokerInfoTabSubmit = useSelector(
    (state) => state?.addComp?.brokerInfoTabSubmit
  );

  const detailTab = useSelector((state) => state?.addComp?.detailTabSubmit);
  const [formValues, setFormValues] = useState(value);
  const [formErrors, setFormErrors] = useState({});
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState(fileObj);
  const [fileName, setFileName] = useState(base64Obj);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (view === "lease") {
        if (Object.keys(formErrors).length !== 0) {
          if (formErrors.parkingStalls || formErrors.parkingCharges) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        }
      } else {
        if (Object.keys(formErrors).length !== 0) {
          if (Object.values(formErrors).every((x) => !x)) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [formErrors, formValues]); //eslint-disable-line react-hooks/exhaustive-deps

  const validateField = (value, name) => {
    if (
      !valideNumber(value) &&
      Boolean(value) &&
      (name === "escrowDays" || name === "parkingRatio")
    ) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Number" });
    } else if (
      !validateDecimal(value) &&
      Boolean(value) &&
      (name === "parkingCharges" || name === "parkingStalls")
    ) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Number" });
    } else if (value.length >= 25 && Boolean(value) && name === "zoning") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Please enter less than 25 characters",
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleImageDialogClickOpen = () => {
    setOpenImageDialog(true);
  };

  const handleImageDialogClickClose = () => {
    setOpenImageDialog(false);
  };

  const handleDeleteFile = async () => {
    setFile(null);
    setFileName(null);
    setOpenImageDialog(true);
  };

  const handleFile = async (displayFile, file) => {
    setLoader(true);
    setDisabled(false);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("avatar", file);
      ProfileService.uploadFile(bodyFormData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setFile(displayFile);
            dispatch(addFile(displayFile));
            setFileName(res.data.response.imageData.key);
            dispatch(addBase64FormatFile(res.data.response.imageData.key));
            setDisabled(true);
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setLoader(false);
          setDisabled(false);
        });
    } catch (error) {
      toast.error(error);
      setLoader(false);
      setDisabled(false);
    }
  };

  const handleSubmit = () => {
    try {
      if (addressTab && basicInfoTab && seller && buyer) {
        const sellerObj = seller.map(({ broker, ...rest }, index) => ({
          ...rest,
          type: 2,
          viewOrder: index + 1,
        }));

        const buyerObj = buyer.map(({ broker, ...rest }, index) => ({
          ...rest,
          type: 1,
          viewOrder: index + 1,
        }));

        const brokers = [...sellerObj, ...buyerObj];

        setLoader(true);
        setDisabled(false);
        let body = {};
        let api;
        if (view === "lease") {
          body = {
            type: 1,
            isConfidential: confidential,
            addressLine1: addressTab.addressLine1,
            addressLine2: addressTab.addressLine2,
            suite: addressTab.suite,
            city: addressTab.city,
            state: addressTab.state,
            zipCode: addressTab.zipCode,
            location: addressTab.location,
            tenantCompanyName: basicInfoTab.tenantCompanyName,
            propertyType: Number(basicInfoTab.propertyType),
            rentType: Number(basicInfoTab.rentType),
            commencement: basicInfoTab.commencement,
            term: Number(basicInfoTab.term),
            termUnit: Number(basicInfoTab.termUnit),
            leaseExpiration: basicInfoTab.leaseExpiration,
            size: unformatNumber(basicInfoTab.size),
            baseRent: unformatNumber(basicInfoTab.baseRent),
            baseRentUnit: Number(unit),
            freeRent: Number(basicInfoTab.freeRent),
            freeRentUnit: basicInfoTab.freeRentUnit,

            escalation: unformatNumber(basicInfoTab.escalation),
            escalationUnit: basicInfoTab.escalationUnit,
            TIS: unformatNumber(basicInfoTab.TIS),
            TISUnit: Number(basicInfoTab.TISUnit),
            leaseType: Number(basicInfoTab.leaseType),
            class: formValues.class,
            parkingStalls: Number(formValues.parkingStalls),
            parkingStallsUnit: "SQFT",
            parkingCharges: Number(formValues.parkingCharges),
            parkingChargesUnit: "MONTH",
            signatureDate: formValues.signatureDate,
            landlordCompanyName: companyValue,
            brokers: brokers,
          };

          if (fileName) {
            body.buildingPhotoUrl = fileName;
          }
          if (basicInfoTab?.expenses) {
            body.expenses = unformatNumber(basicInfoTab.expenses);
            body.expensesUnit = Number(unit);
          }

          if (basicInfoTab?.effectiveRent) {
            body.effectiveRent = unformatNumber(basicInfoTab.effectiveRent);
            body.effectiveRentUnit = Number(unit);
          }

          api = CompService.addCompsLease;
        } else {
          body = {
            type: 2,
            isConfidential: confidential,
            addressLine1: addressTab.addressLine1,
            addressLine2: addressTab.addressLine2,
            suite: addressTab.suite,
            city: addressTab.city,
            state: addressTab.state,
            zipCode: addressTab.zipCode,
            location: addressTab.location,
            buyerName: basicInfoTab.buyerName,
            propertyType: Number(basicInfoTab.propertyType),
            class: basicInfoTab.class,
            escrowCloseDate: basicInfoTab.escrowCloseDate,
            salePrice: unformatNumber(basicInfoTab.salePrice),
            size: unformatNumber(basicInfoTab.sizeSale),
            NOI: unformatNumber(basicInfoTab.NOI),
            capRate: basicInfoTab.capRate || "N/A",
            sellerCompanyName: companyValue,
            brokers: brokers,
            buildingPhotoUrl: fileName,
            yearBuilt: Number(formValues.yearBuilt),
            zoning: formValues.zoning,
            escrowDays: Number(formValues.escrowDays),
            parkingRatio: Number(formValues.parkingRatio),
            saleComment: formValues.saleComment,
          };
          api = CompService.addCompsSale;
        }

        api(body)
          .then((res) => {
            toast.success(res.data.message);
            dispatch(reInitialize());
            navigate("../comp-list");
            setLoader(false);
            setDisabled(true);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setLoader(false);
            setDisabled(true);
          });
      }
    } catch (error) {
      console.log("Validation failed");
    }
  };

  const handleBackButton = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleAlertSubmit = () => {
    handleBack();
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        {view === "lease" && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl>
                <h4 className="subtext mb-5 thin">Class</h4>
                <RadioGroup
                  name="class"
                  className="chip-label"
                  value={formValues?.class}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className={`${formValues?.class === "A" ? "active" : ""}`}
                    value="A"
                    control={<Radio />}
                    label="A"
                  />
                  <FormControlLabel
                    className={`${formValues?.class === "B" ? "active" : ""}`}
                    value="B"
                    control={<Radio />}
                    label="B"
                  />
                  <FormControlLabel
                    className={`${formValues?.class === "C" ? "active" : ""}`}
                    value="C"
                    control={<Radio />}
                    label="C"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Parking</h4>
              <div className="bg-light-blue">
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="subtext mb-5">Stalls</h4>
                    <TextField
                      required
                      placeholder="Per 1000 SQFT"
                      variant="standard"
                      name="parkingStalls"
                      value={formValues?.parkingStalls || ""}
                      onChange={handleChange}
                      error={Boolean(formErrors?.parkingStalls)}
                      helperText={formErrors?.parkingStalls}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formValues?.parkingStalls ? "/ 1000 SQFT" : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="subtext mb-5">Parking Charges</h4>
                    <TextField
                      className={
                        formValues?.parkingCharges
                          ? "input-pl-0"
                          : "cap-rate-perc"
                      }
                      placeholder="$ / STALL / MONTH"
                      variant="standard"
                      name="parkingCharges"
                      value={formValues?.parkingCharges || ""}
                      onChange={handleChange}
                      error={Boolean(formErrors?.parkingCharges)}
                      helperText={formErrors?.parkingCharges}
                      disabled={!formValues?.parkingStalls}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {formValues?.parkingCharges ? "$" : ""}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {formValues?.parkingCharges ? "STALL / MONTH" : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 ">Signature Date</h4>
              <form autoComplete="off">
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  selected={formValues?.signatureDate}
                  name="signatureDate"
                  placeholderText="Select Date"
                  maxDate={new Date()}
                  onChange={(e) =>
                    handleChange({
                      target: { name: "signatureDate", value: e },
                    })
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </form>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="space-between mb-5">
                <h4 className="subtext ">Upload Building Photo</h4>
                {file && (
                  <EditOutlinedIcon
                    className="color-secondary"
                    onClick={handleImageDialogClickOpen}
                  />
                )}
              </div>
              <div className="add-property-image">
                {!file ? (
                  <div className="default-look">
                    <img
                      src={addIcon}
                      alt="add-pic"
                      onClick={handleImageDialogClickOpen}
                    />
                    <h2 className="subtext mt-10 bold">ADD</h2>
                  </div>
                ) : (
                  <img
                    alt="uploaded-img"
                    className="uploaded-imgage"
                    src={file}
                  />
                )}
              </div>
            </Grid>
          </>
        )}

        {view === "sale" && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Year Built</h4>
              {!formValues?.yearBuilt && (
                <p className="virtual-placeholder for-outline">Select Year</p>
              )}
              <Select
                variant="outlined"
                placeholder="Select Date"
                name="yearBuilt"
                value={formValues?.yearBuilt || ""}
                onChange={handleChange}
              >
                {generateYears(1901, new Date().getFullYear())
                  .sort(function (a, b) {
                    return b - a;
                  })
                  .map((year, i) => (
                    <MenuItem key={`${year}-${i}`} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Zoning</h4>
              <TextField
                placeholder="Enter Zoning"
                variant="outlined"
                name="zoning"
                value={formValues?.zoning || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.zoning)}
                helperText={formErrors?.zoning}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <span className="zoning-helper">upto 25 characters</span>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Days in Escrow</h4>
              <TextField
                placeholder="Enter Days"
                variant="outlined"
                name="escrowDays"
                value={formValues?.escrowDays || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.escrowDays)}
                helperText={formErrors?.escrowDays}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.escrowDays && formValues?.escrowDays != 1
                        ? "Day(s)"
                        : formValues?.escrowDays
                        ? "Day"
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Parking Ratio</h4>
              <TextField
                placeholder="Per 1000 SQFT"
                variant="outlined"
                name="parkingRatio"
                value={formValues?.parkingRatio || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.parkingRatio)}
                helperText={formErrors?.parkingRatio}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.parkingRatio ? "Per 1000 SQFT" : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h4 className="subtext mb-5">Sale Comment</h4>
              <TextField
                placeholder="Type here..."
                variant="outlined"
                multiline
                minRows="3"
                name="saleComment"
                value={formValues?.saleComment || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="space-between mb-5">
                <h4 className="subtext ">Upload Building Photo</h4>
                {file && (
                  <EditOutlinedIcon
                    className="color-secondary"
                    onClick={handleImageDialogClickOpen}
                  />
                )}
              </div>
              <div className="add-property-image">
                {!file ? (
                  <div className="default-look">
                    <img
                      src={addIcon}
                      alt="add-pic"
                      onClick={handleImageDialogClickOpen}
                    />
                    <h2 className="subtext mt-10 bold">ADD</h2>
                  </div>
                ) : (
                  <img
                    alt="uploaded-img"
                    className="uploaded-imgage"
                    src={file}
                  />
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
      {addressTabSubmit && basicInfoTabSubmit && brokerInfoTabSubmit}
      <div className="btn-cover center">
        <button
          onClick={handleBackButton}
          // disabled={disabled}
          className="btn btn-secondary-border"
        >
          Back
        </button>

        <button
          disabled={
            !(
              addressTabSubmit === false &&
              basicInfoTabSubmit === false &&
              brokerInfoTabSubmit === false &&
              detailTab === true &&
              disabled
            )
          }
          onClick={handleSubmit}
          className="btn btn-secondary"
        >
          {!loader ? "Submit" : "Loading..."}
        </button>
      </div>
      <ImageUpload
        open={openImageDialog}
        file={file}
        handleClose={handleImageDialogClickClose}
        handleFile={handleFile}
        handleDeleteFile={handleDeleteFile}
      />
      <AlertDialog
        show={false}
        message={COMP.CONFIRM_MESSAGE}
        open={alertOpen}
        handleClose={handleAlertClose}
        showConfirm={true}
        handleAlertSubmit={handleAlertSubmit}
      />
    </>
  );
}
