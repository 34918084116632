import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Link, Route, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ERROR } from "../../utils/Constant";
import AuthService from "../../services/component/Auth";
import EmailAlert from "../../components/dialog/EmailAlert";
import {
  cleanObject,
  validatePassword,
  validatePhoneNumber,
  numberFormat,
  validateEmail,
  formatPhoneNumber,
  decryptParam,
} from "../../utils/Helper";
import { AUTH_VERIFY_EMAIL } from "../../utils/Constant";

export default function SignUp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const emailParam = searchParams.get("data");
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    mobile: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [decryptedData, setDecryptedData] = useState("");

  const handleAlertClose = () => {
    setOpen(false);
    navigate("../signin");
  };

  const handleAlertLater = () => {
    setOpen(false);
  };

  const parseIfJson = (data) => {
    if (typeof data !== "string") return data;
    try {
      return JSON.parse(data); // Try parsing as JSON
    } catch (error) {
      return data; // Return original data if parsing fails
    }
  };

  useEffect(() => {
    if (emailParam) {
      const email = decryptParam(
        emailParam,
        process.env.REACT_APP_ENC_KEY,
        process.env.REACT_APP_IV_KEY
      );
      if (email) {
        setDecryptedData(parseIfJson(email));
      }
      if (email && typeof email === "object") {
        let param = JSON?.parse(email);
        if (param?.mobile && param?.email) {
          formValues.email = param?.email;
          formValues.mobile = param?.mobile;
        } else if (param?.mobile) formValues.mobile = param?.mobile;
        else formValues.email = param?.email;
      } else if (email) {
        formValues.email = email;
      }
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      (formValues["email"] || formValues["mobile"]) &&
      formValues["password"] &&
      checked &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, checked, formValues]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const number = formatPhoneNumber(value);
      setFormValues({ ...formValues, [name]: number });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    validate(value, name);
  };

  const handleAgreement = (e) => {
    setChecked(e.target.checked);
    setDisabled(true);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    try {
      const obj = cleanObject(formValues);
      const body = { ...obj };
      if (body.mobile) {
        body.mobile = numberFormat(body.mobile);
      }
      let api = AuthService.signupUser;

      if (emailParam) {
        api = AuthService.acceptInvitation;
      }

      api(body)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("profile", "false");
            if (emailParam || res?.data?.response?.isInvited) {
              navigate("/user/profile");
              localStorage.setItem(
                "token",
                JSON.stringify(res.data.response.token)
              );
            } else {
              if (body.email && !body.hasOwnProperty("mobile")) {
                navigate({
                  pathname: `../verify-otp/${res.data.response.token}/email/${obj.email}`,
                });
              } else {
                navigate({
                  pathname: `../verify-otp/${res.data.response.token}/mobile/${obj.mobile}`,
                });
              }
            }
          }
        })
        .catch((error) => {
          setDisabled(false);
          setLoader(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "mobile":
        validateField(
          value,
          name,
          validatePhoneNumber,
          ERROR.MOBILE_REQUIRED,
          ERROR.INVALID_MOBILE
        );

        break;

      case "email":
        validateField(
          value,
          name,
          validateEmail,
          ERROR.EMAIL_REQUIRED,
          ERROR.INVALID_EMAIL
        );

        break;

      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value) && name === "password") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const isEmailDisabled = () => {
    if (decryptedData) {
      if (typeof decryptedData === "object" && decryptedData?.email)
        return true;
      if (typeof decryptedData === "string") return true;
    }
    return false;
  };

  return (
    <div className="page-signup">
      <h1 className="bold mb-10">Sign Up</h1>
      <h2 className="subtext mb-20">To get started</h2>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h4 className="subtext mb-5">Mobile Number</h4>
          <TextField
            name="mobile"
            variant="outlined"
            placeholder="Enter Number"
            disabled={!!decryptedData?.mobile}
            value={formValues?.mobile || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.mobile)}
            helperText={formErrors?.mobile}
          />
        </Grid>
        <Grid item xs={12}>
          <h4 className="subtext mb-5">Email</h4>
          <TextField
            name="email"
            variant="outlined"
            placeholder="Enter Email"
            value={formValues?.email || ""}
            disabled={isEmailDisabled()}
            onChange={handleChange}
            error={Boolean(formErrors?.email)}
            helperText={formErrors?.email}
          />
        </Grid>
        <Grid item md={12} className="signup-pass">
          <h4 className="subtext mb-5 required">Password</h4>
          <TextField
            name="password"
            variant="outlined"
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            value={formValues?.password || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.password)}
            helperText={formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    className="color-secondary"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="justify-start accept-check">
            <Checkbox checked={checked} onChange={handleAgreement} />
            <span>
              I accept the{" "}
              <a href="https://broker2broker.app/legal"> Terms of Use</a> &{" "}
              <a href="https://broker2broker.app/privacy">Privacy Policy</a>{" "}
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <button
            onClick={handleSubmit}
            disabled={disabled}
            className="btn btn-secondary w-100 mb-30 "
          >
            {!loader ? "Sign Up" : "Loading..."}
          </button>
        </Grid>
      </Grid>
      <h2 className="center subtext mt-30">
        Already have an account?{" "}
        <Link to="/auth/signin" className="color-secondary">
          Sign In
        </Link>
      </h2>
      <EmailAlert
        skiplater={false}
        skipOkay={true}
        open={open}
        handleAlertClose={handleAlertClose}
        handleAlertLater={handleAlertLater}
        header={AUTH_VERIFY_EMAIL.HEADER}
        message={AUTH_VERIFY_EMAIL.MESSAGE}
        button={AUTH_VERIFY_EMAIL.BUTTON}
      />
    </div>
  );
}
